<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom">
					<h1>Minha Conta</h1>
				</div>
				<menu-tabs
					:items="[
						{ label: 'Dados Pessoais', slug: 'my-data', isActive: false },
						{ label: 'Meus Endereços', slug: 'adresses', isActive: true },
						{ label: 'Meus Cartões', slug: 'cards', isActive: false },
					]"
					@scrollTo="scrollParent"
					ref="tabs"
					class="container-ddh-menu"
				>
				</menu-tabs>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data va-row" id="myData">
						<div class="flex xs12 no-padding">
							<h4>Editar Endereço</h4>
						</div>
					</div>

					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row" v-if="addressEdit !== null">
									<div class="flex xs12 item no-padding">
										<RadioButton
											class="box-payment box-method"
											:value="false"
											:selected="getSelectedAddress(addressEdit.id)"
											@click="selectAddress(addressEdit.id, address, index)"
										>
											<div class="va-row">
												<div class="flex xs12 md8">
													<b
														>{{ addressEdit.address1 }},
														{{ addressEdit.addressNumber }} -
														{{ addressEdit.district }}</b
													><br />
													{{ addressEdit.city }} - {{ addressEdit.stateId }} -
													CEP: {{ addressEdit.zipPostalCode }}<br />
												</div>

												<div class="flex xs12 md4 item">
													<div class="editor">
														<router-link
															:to="{
																name: 'address-edit',
																params: {
																	address: addressEdit,
																	subscription: subscription,
																},
															}"
															class="btn btn-micro-edit"
															>EDITAR</router-link
														>
													</div>
												</div>
											</div>
										</RadioButton>

										<br />
									</div>
								</div>

								<hr />

								<form @submit.prevent="saveAddress">
									<div v-if="addressNotUsedEdit !== null">
										<h4>Mais endereços</h4>

										<div class="more-address">
											<div
												v-for="(address, index) in addressNotUsedEdit"
												:key="index"
											>
												<div class="va-row address-row">
													<div class="flex xs12 va-row no-padding">
														<div class="flex xs12 no-padding">
															<RadioButton
																class="box-payment box-method"
																:value="false"
																:selected="getSelectedAddress(address.id)"
																@click="
																	selectAddress(address.id, address, index)
																"
															>
																<b
																	>{{ address.address1 }},
																	{{ address.addressNumber }} -
																	{{ address.district }}</b
																><br />
																{{ address.city }} - {{ address.stateId }} -
																CEP: {{ address.zipPostalCode }}<br />
															</RadioButton>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="flex md12 text-right no-padding buttons-list">
										<button type="submit" class="btn btn-primary right">
											UTILIZAR ENDEREÇO
										</button>

										<router-link
											:to="{
												name: 'address-edit',
												params: {
													address: emptyAddress,
													subscription: subscription,
												},
											}"
											class="btn btn-primary right cancel"
										>
											ADICIONAR UM NOVO ENDEREÇO
										</router-link>

										<div class="clearfix"></div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="flex xs3 subscription-box">
              <div class="panel newsletter">
                Deseja utilizar esse cartão no Clube?<br>

                  <div v-for="(subscriptionItem, index) in subscriptionsData" :key="index">

                    <RadioButton class="box-payment box-method" :value="false" :selected="getSelectedId(subscriptionItem.id)" @click="selectSubscription(subscriptionItem.id, index)">
                      <div>{{subscriptionItem.characterName}}</div>
                    </RadioButton>
                  </div>

                  <div v-if="subscriberEditing" class="editing">
                    <button type="submit" class="btn btn-primary right" v-on:click="saveAddress()">SALVAR EDIÇÃO</button>
                  </div>

              </div>
            </div> -->
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "address-select",
		directives: { mask },
		components: {
			// vuescroll
			// VueScrollTo
			// DataVisualisationTab,
		},

		mounted() {
			if (localStorage.addressSelected && this.address === undefined) {
				this.addressEdit = JSON.parse(localStorage.addressSelected);
				this.addressNotUsedEdit = JSON.parse(localStorage.addressNotUsed);
				this.addressSelected = this.addressEdit.id;
				//this.subscriptionSend.subscriptions.push(this.subscription.id)
			} else {
				localStorage.addressSelected = JSON.stringify(this.address);
				localStorage.addressNotUsedEdit = JSON.stringify(this.addressNotUsed);

				this.addressEdit = this.address;
				this.addressNotUsedEdit = this.addressNotUsed;
				this.addressSelected = this.address.id;
				// this.subscriptionSend.subscriptions.push(this.subscription.id)
			}

			axios
				.get(process.env.VUE_APP_ROOT_API + "/customer/subscriptions")
				.then((response) => {
					this.subscriptionsData = response.data.data.subscriptions;
					this.updateSubscriptionSend();
				})
				.catch((e) => {
					this.errors.push(e);
				});

			// this.addressEdit = this.address
			// this.addressNotUsedEdit = this.addressNotUsed

			if (this.addressEdit.nameRecipient !== undefined) {
				this.addressEdit.name = this.addressEdit.nameRecipient
					.split(" ")
					.slice(0, -1)
					.join(" ");
				this.addressEdit.lastname = this.addressEdit.nameRecipient
					.split(" ")
					.slice(-1)
					.join(" ");
			}
		},

		props: ["address", "subscription", "addressNotUsed"],

		data() {
			return {
				subscriptionsData: null,

				addressEdit: null,
				addressNotUsedEdit: null,

				subscriberEditing: false,
				subscriptionSend: {
					subscriptions: [],
					address: this.addressEdit,
				},
				percentCall: 0,
				addressSelected: null,

				emptyAddress: {
					addressLabel: "",
					addressTypeName: "",
					zipPostalCode: "",
					address1: "",
					district: "",
					city: "",
					stateId: "",
					addressNumber: "",
					address2: "",
					addressReference: "",
					name: "",
					lastname: "",
					phoneNumber: "",
				},
			};
		},

		methods: {
			updateSubscriptionSend() {
				const self = this;

				self.subscriptionsData.forEach((element) => {
					if (element.shippingAddressId === self.address.id) {
						let subscriptionId = { id: element.id };
						self.subscriptionSend.subscriptions.push(subscriptionId);
					}
				});
			},

			created: function () {
				if (this.addressEdit.zipPostalCode.length === 9) {
					axios
						.get(
							`https://api.pagar.me/1/zipcodes/${this.addressEdit.zipPostalCode}`
						)
						.then((res) => {
							this.addressEdit.stateId = res.data.state;
							this.addressEdit.city = res.data.city;
							this.addressEdit.district = res.data.neighborhood;
							this.addressEdit.address1 = res.data.street;
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},

			updateAndVerifyCep({ e, target }) {
				this.addressEdit.zipPostalCode = target.value;
				this.created();
			},

			selectSubscription(id, index) {
				this.subscriberEditing = true;
				let subscriptionId = { id: id };

				// alert(this.subscriptionsData[index].shippingAddressId);
				// this.subscriptionsData[index].shippingAddressId = this.address.id

				var index = this.subscriptionSend.subscriptions.indexOf(
					this.subscriptionSend.subscriptions.find((c) => {
						return c.id === id;
					})
				);
				if (index > -1) {
					this.subscriptionSend.subscriptions.splice(index, 1);
				} else {
					this.subscriptionSend.subscriptions.push(subscriptionId);
				}
			},

			selectAddress(id, address, index) {
				this.addressSelected = id;
				this.subscriptionSend.address = address;
			},

			getSelectedAddress(id) {
				let response = false;

				if (this.addressSelected === id) {
					response = true;
				}

				return response;
			},

			getSelectedId(id) {
				let response = false;

				let objectId = this.subscriptionSend.subscriptions.find((c) => {
					return c.id === id;
				});
				if (objectId) {
					if (objectId.id === id) {
						response = true;
					}
				}

				// if(id == this.address.id){
				//   response = true
				// }

				return response;
			},

			saveAddress() {
				const self = this;

				// this.subscriptionSend.address = this.addressEdit

				axios
					.put(
						process.env.VUE_APP_ROOT_API + "/customer/address",
						this.subscriptionSend
					)
					.then(function (response) {
						self.$router.push({ name: "adresses" });
						self.percentCall = 0;
					})
					.catch(function (error) {
						console.log(error);
					});
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.radio-button-content {
		width: 98%;
	}

	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.buttons-list {
		margin: 20px 0px;
		margin-bottom: 10px;
	}

	::v-deep .radio-button-icon {
		margin-right: 20px;
		margin-left: 5px;
	}

	@media (max-width: 375px) {
		::v-deep .radio-button-icon {
			max-height: 17px;
			max-width: 17px;
		}
	}

	.item {
		position: relative;

		@media (max-width: 375px) {
			::v-deep .radio-button-icon {
				max-height: 17px;
				max-width: 17px;
			}
		}
	}

	.address-row {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 15px 20px 5px 24px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 20px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 28px;
			top: 47%;
			@media (max-width: 990px) {
				right: auto !important;
				left: 0px !important;
			}
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 28px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 28px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		margin-top: 40px;
		padding: 31px;
		border: solid 2px #f0ebf5;
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}
</style>
